/* Scoped styles for the custom table */
.custom-table-container {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 500px; /* Limit the height of the table container */
    overflow: hidden;
}

/* Table styling */
.custom-table-container .table {
    width: 100%;
  /*  border: 2px solid #09b8aa; /* Border color */
    border-radius: 12px; /* Curved corners for the table */
    overflow: hidden; /* Prevent content from overflowing the rounded corners */
    border-collapse: separate;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
}

/* Styling for the table body to enable scrolling */
.custom-table-container .table-responsive {
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    max-height: 400px; /* Limit the height of the table body */
    overflow-y: auto; /* Enable vertical scrolling if the content exceeds the max height */
}

/* Table header styling */
.custom-table-container .table th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #09b8aa; /* Bottom border for header */
    padding: 15px;
    text-align: center;
    font-weight: bold;
}

/* Table row styling */
.custom-table-container .table td {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1; /* Light border between rows */
    text-align: center;
}

/* Hover effect on rows */
.custom-table-container .table-hover tbody tr:hover {
    background-color: #f1f1f1;
}

/* Styling for responsive container */
.custom-table-container .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

input[type="checkbox"] {
    margin-right: 18px; /* Add space between checkbox and next label */
}

/* Default styles for larger screens (desktop view) */
.responsive-td {
    padding: 10px;
    text-align: center; /* Default horizontal alignment */
}

.checkbox-container {
    display: inline-block; /* Default: horizontally aligned */
    margin-right: 10px; /* Space between checkboxes */
}

.checkbox-container label {
    margin-right: 5px; /* Space between label and checkbox */
}

.checkbox-container input {
    margin-left: 5px; /* Space between checkbox and label */
}

/* For screens smaller than 768px (mobile view) */
@media (max-width: 768px) {
    .responsive-td {
        text-align: left; /* Align text left on mobile */
    }

    .checkbox-container {
        display: flex; /* Use flex for vertical stacking */
        flex-direction: column; /* Stack checkboxes vertically */
        margin-bottom: 8px; /* Space between checkboxes */
    }

    .checkbox-container label {
        margin-bottom: 4px; /* Space between label and checkbox */
    }

    .checkbox-container input {
        margin-bottom: 5px; /* Space between checkbox and label */
        width: 18px; /* Optional: smaller checkbox size on mobile */
        height: 18px;
    }

    /* Optionally, you can add margins/padding to make the content more mobile-friendly */
    .responsive-td div {
        margin-bottom: 10px; /* Add space between the date and the checkboxes */
    }
}
