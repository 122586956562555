.custom-select-sm {
    font-size: 0.875rem; /* Smaller font size */
    padding: 0.25rem 0.5rem; /* Adjust padding */
    height: auto; /* Adjust height if needed */
	 width: 150px;
}

.form-label {
    font-size: 0.875rem; /* Adjust font size for the label */
}
