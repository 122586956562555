.local-conveyance {
    background-color: #f9f9f9; /* Light background color */
    border: 2px solid #990000; /* Border color */
    border-radius: 5px; /* Rounded corners */
    padding: 15px; /* Spacing inside the border */
    margin: 20px 0; /* Margin around the section */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.local-conveyance h2 {
    color: #990000; /* Title color */
    margin-bottom: 10px; /* Space below the title */
}
.input-font{font-size: 12px; height:22px; padding: 3px;}

.expense-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

.expense-table th,
.expense-table td {
    padding: 1px;
    border: 1px solid #ddd;
    text-align: left;
}

.header-row {
    background-color: #f2f2f2;
}

@media screen and (max-width: 768px) {
    .expense-table thead {
        display: none; /* Hide the header */
    }

    .expense-table, 
    .expense-table tbody, 
    .expense-table tr, 
    .expense-table td {
        display: block; /* Make the table rows block */
        width: 100%; /* Full width */
    }

    .expense-table tr {
        margin-bottom: 15px; /* Space between rows */
    }

    .expense-table td {
        text-align: right; /* Align text to the right */
        position: relative;
        padding-left: 50%; /* Space for pseudo-element */
    }

    .expense-table td:before {
        content: attr(data-label); /* Use data-label for header */
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        text-align: left; /* Align header text */
        font-weight: bold;
    }
}

.transport-table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
}

.transport-table th,
.transport-table td {
    padding: 3px;
    border: 1px solid #ddd;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .transport-table thead {
        display: none; /* Hide the header */
    }

    .transport-table,
    .transport-table tbody,
    .transport-table tr,
    .transport-table td {
        display: block; /* Make the table rows block */
        width: 100%; /* Full width */
    }

    .transport-table tr {
        margin-bottom: 15px; /* Space between rows */
    }

    .transport-table td {
        text-align: right; /* Align text to the right */
        position: relative;
        padding-left: 50%; /* Space for pseudo-element */
    }

    .transport-table td:before {
        content: attr(data-label); /* Use data-label for header */
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        text-align: left; /* Align header text */
        font-weight: bold;
    }
}

.transport-table-container {
    position: relative; /* Allows positioning of button */
    padding: 10px; /* Add some padding around the table */
}

.add-button {
    position: absolute;
    top: 10px; /* Adjust this value as needed */
    right: 10px; /* Position the button at the top right */
    z-index: 1; /* Ensure it's on top of other elements */
}

@media screen and (max-width: 768px) {
    .add-button {
        top: 10px; /* Adjust the position for mobile */
        right: 10px; /* Position remains the same */
        display: block; /* Make sure it displays properly */
    }
}

.expense-table-container {
    position: relative; /* Allow positioning of the button */
    padding: 10px; /* Add padding around the container */
}
.form-group { margin-bottom: 0.3rem;}
label {
    display: inline-block;
    margin-bottom: .1rem;
}