.hand-nav-item {
    display: flex;
    align-items: center;
    position: relative;
}

.hand-icon {
    width: 30px; /* Adjust size as needed */
    margin-right: 5px; /* Space between hand and bell */
    transition: transform 0.2s; /* Smooth transition for hover effect */
}

.hand-icon:hover {
    transform: scale(1.2); /* Slightly enlarge on hover */
}

@keyframes sway {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-15px);
    }
    100% {
        transform: translateX(0);
    }
}

.animated-hand {
    animation: sway 1s ease-in-out infinite;
}

@media (max-width: 768px) {
    .main-header .navbar-nav .nav-item button {
        color: white;
        font-size: 18px;
    }
}