/* Wrapper for centering the loader */
.loader-container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.1); /* Slight overlay */
  color: #333;
}

/* Spinner style */
.spinner1 {
  border: 8px solid #f3f3f3;  /* Light grey background */
  border-top: 8px solid #3498db;  /* Blue color for the spinner */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin1 2s linear infinite;  /* Animation for spinning effect */
}

/* Animation to rotate the spinner */
@keyframes spin1 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Text below the spinner */
.loader-container1 p {
  font-size: 1.5rem;
  margin-top: 10px;
  font-weight: bold;
  color: #3498db; /* Matching text color with spinner */
  animation: fadeIn1 1.5s ease-in-out infinite;
}

/* Fading animation for the loading text */
@keyframes fadeIn1 {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}