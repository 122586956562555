/* customToastStyles.css */

.Toastify__toast--success {
    background-color: #dff0d8; /* Light green background */
    color: #3c763d; /* Dark green text */
}

.Toastify__toast--error {
    background-color: #f2dede; /* Light red background */
    color: #a94442; /* Dark red text */
}

.Toastify__toast--info {
    background-color: #d9edf7; /* Light blue background */
    color: #31708f; /* Dark blue text */
}

.Toastify__toast--default {
    background-color: #f5f5f5; /* Light gray background */
    color: #333; /* Dark gray text */
}

/* Optional: Additional customization for the toast container */
.Toastify__toast-container {
    font-family: Arial, sans-serif;
}

.custom-select .react-select__control {
    display: none; /* Hide the select control */
  }
  