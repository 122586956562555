/* styles.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Darker background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 30px; /* Increased padding for more space */
    border-radius: 8px; /* More rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Soft shadow */
    max-width: 500px; /* Set a max width */
    width: 100%; /* Responsive width */
    text-align: center;
    animation: fadeIn 0.3s; /* Fade-in effect */
}

.modal-content h2 {
    margin-bottom: 20px; /* Space between title and text */
    font-size: 1.5em; /* Bigger title */
    color: #333; /* Darker title color */
}

.modal-content p {
    margin-bottom: 30px; /* Space between text and buttons */
    font-size: 1em; /* Normal text size */
    color: #555; /* Lighter text color */
}

.modal-content button {
    margin: 0 10px; /* Space between buttons */
    padding: 10px 20px; /* Padding for buttons */
    font-size: 1em; /* Button text size */
    border: none;
    border-radius: 5px; /* Rounded buttons */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Transition for hover effect */
}

.modal-content button:hover {
    background-color: #007bff; /* Blue background on hover */
    color: white; /* White text on hover */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
