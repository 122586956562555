/* Sidebar.css */

.sidebar {
    padding-top: 20px; /* Optional: Adjust padding as needed */
}

.main-content {
    margin-left: 250px; /* Adjust this value according to your sidebar width */
    padding: 20px;      /* Add padding for main content */
    height: 100vh;      /* Full height */
    overflow-y: auto;   /* Enable scrolling for main content */
    transition: margin-left 0.3s ease; /* Smooth transition for margin changes */
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
    .main-sidebar {
        width: 200px; /* Reduce width for smaller screens */
    }

    .main-content {
        margin-left: 200px; /* Adjust this value according to your sidebar width */
    }

    /* Optional: Hide sidebar on mobile, you can toggle its visibility using JS */
    .main-sidebar.sidebar-collapsed {
        width: 60px; /* Collapse width */
    }

    .main-content.sidebar-collapsed {
        margin-left: 60px; /* Adjust main content margin */
    }
}

.os-content-arrange {
    height: 0vh !important; /* Disable height for specific elements */
}

.os-content-glue {
    height: 0vh !important; /* Disable height for specific elements */
}

.main-sidebar.collapsed {
    width: 60px; /* Adjust as needed */
    transition: width 0.3s;
}

.main-sidebar.collapsed .nav-link p {
    display: none; /* Hide text in collapsed mode */
}

.toggle-sidebar {
    position: absolute;
    top: 10px;
    left: 10px; /* Adjust position */
    background: transparent;
    border: none;
    cursor: pointer;
    color: white; /* Adjust for visibility */
}

.sidebar-header {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between items */
    padding: 10px; /* Add some padding */
}

.toggle-button {
    display: none; /* Hide by default */
}

/* Show toggle button only on mobile screens */
@media (max-width: 768px) { /* Adjust the breakpoint as needed */
    .toggle-button {
        display: flex; /* Display as flex to align items */
        align-items: center; /* Center vertically */
    }
}
