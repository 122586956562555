/* Custom styles for Change Password page */
.changepassword1-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f5;
  font-family: 'Roboto', sans-serif;
}

.change-password-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  padding: 30px;
}

.form-card {
  padding: 20px;
  text-align: center;
}

.form-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  color: #555;
  font-size: 1rem;
}

.required {
  color: red;
}

.input-field {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  margin-top: 8px;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #007bff;
}

.submit-btn {
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.form-footer {
  margin-top: 20px;
}
