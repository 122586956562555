/* Loader */
.loader {
    text-align: center;
    margin-top: 50px;
}

.spinner-border {
    width: 50px;
    height: 50px;
    border-width: 6px;
}

/* Dashboard Title */
.dashboard-title {
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

/* Dashboard Tiles */
.dashboard-tile {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
    
}

.dashboard-tile:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.dashboard-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.dashboard-image {
    width: 80px;
    height: 80px;
    object-fit: contain;
    
}

.dashboard-info {
    text-align: center;
}

.tile-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.tile-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.icon-container {
    font-size: 20px;
    color: #007bff; /* Default Blue */
    cursor: pointer;
    transition: all 0.3s ease;
}

.icon-container:hover {
    transform: scale(1.1);
}

/* Tooltip styling */
.icon-container::after {
    content: attr(title);
    position: absolute;
    opacity: 0;
    background-color: #333;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    transition: opacity 0.3s;
    visibility: hidden;
    transform: translateX(-50%) translateY(-5px);
    bottom: 30px;
}

.icon-container:hover::after {
    opacity: 1;
    visibility: visible;
}

/* Custom color for each status */
.icon-container.total {
    color: #007bff; /* Blue for Total Transactions */
}

.icon-container.approved {
    color: #28a745; /* Green for Approved Transactions */
}

.icon-container.pending {
    color: #ff7f00; /* Orange for Pending Transactions */
}

.icon-container.rejected {
    color: #dc3545; /* Red for Rejected Transactions */
}

/* Hover effects */
.icon-container.total:hover {
    color: #0056b3;
}

.icon-container.approved:hover {
    color: #218838;
}

.icon-container.pending:hover {
    color: #e67e00;
}

.icon-container.rejected:hover {
    color: #c82333;
}

/* Responsive Layout */
.dashboard-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
}

@media (max-width: 768px) {
    .col-lg-3 {
        flex: 1 1 calc(50% - 20px);
    }
}

.g {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background: #0f9d58;
    margin: 10px
  }
  
  .r {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background: #EB4537;
    margin: 10px
  }
  
  .b {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background: #4285f4;
    margin: 10px;
  }
  
  .y {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background: #FAC230;
    margin: 10px;
  }
  
  .dots {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px;
  }
  
  .dots > div {
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-name: bounce;
    transform-origin: center bottom;
  }
  
  .dots > div.r {
    animation-duration: 1s;
  }
  
  .dots > div.g {
    animation-duration: 1.5s;
  }
  
  .dots > div.b {
    animation-duration: 2s;
  }
  
  .dots > div.y {
    animation-duration: 2.5s;
  }
  
  @keyframes bounce {
    from, 20%, 53%, 80%, to {
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transform: translate3d(0, -30px, 0);
    }
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transform: translate3d(0, -15px, 0);
    }
    90% {
      transform: translate3d(0, -4px, 0);
    }
  }